import styled from "@emotion/styled"
import tw from "twin.macro"
import { Hamburger } from "./Icons/HamburgerMenu";
import { useState } from "react";

interface NavHeaderProps {
    pageName: `Home` | 'Compare'
}

export const NavHeader = ({pageName}: NavHeaderProps) => {
    const HeaderContainer = styled.div`
        ${tw`flex flex-row basis-full`}
    `;
    const NavButton = styled.button`
    ${tw`flex basis-12 flex-none justify-center border-none bg-buttonBase mb-2`}
    `;

    const NavHeader = styled.div`
        ${tw`flex flex-grow justify-start bg-backgroundAccent text-primary mb-2 pl-2`}
    `;

    const [showMenu, setShowMenu] = useState<boolean>(false);
    return (
        <HeaderContainer>
            <NavButton onClick={() => setShowMenu(!showMenu)}>
                <Hamburger />
            </NavButton>
            <NavHeader>
                <h1>{pageName}</h1>
            </NavHeader>
        </HeaderContainer>
    )
}